@tailwind base;

.border-error {
  @apply border border-solid border-red-0 rounded-5px;
}

.col-name-left {
  @apply font-bold text-grey-9;
}

.col-name {
  @apply font-bold text-grey-9 text-right;
}

.col-name-ui {
  @apply col-name mt-07;
}

@tailwind components;
@tailwind utilities;

:root {
  --color-main: #583ba8;
  --color-primary: #3b81b9;
  --color-secondary: #25848d;
  --color-tertiary: #5cc3b2;

  --color-accent-0: #C99300;
  --color-accent-1: #a53f27;
  --color-accent-2: #f8f8f8;
  --color-accent-3: #db3594;
  --color-red-0: #ff0000;
  --color-green-0: #1DB393;
  --color-green-1: #ecffe7;
  --color-blue-0: #4466bb;
  --color-blue-1: #007bff;

  --color-dark: #000000;
  --color-light: #ffffff;

  --color-grey-0: #050505;
  --color-grey-1: #111111;
  --color-grey-2: #222222;
  --color-grey-3: #333333;
  --color-grey-4: #444444;
  --color-grey-5: #505050;
  --color-grey-6: #606060;
  --color-grey-7: #727272;
  --color-grey-8: #878f9a;
  --color-grey-9: #909090;
  --color-grey-A: #aaaaaa;
  --color-grey-B: #b7b5b1;
  --color-grey-C: #cccccc;
  --color-grey-D: #dedede;
  --color-grey-E: #eeeeee;
  --color-grey-F: #f0f0f0;

  --animation-duration: 0.35s;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-infinite {
  animation: rotation 1.4s infinite linear;
}

.toast-warning-container {
  .Toastify__toast--warning, .Toastify__close-button--warning {
    color: var(--color-grey-3);
  }
}

.icon-arrow {
  width: 30px;
  height: 22px;
  display: inline-block;
  position: relative;
  margin: 0 6px;

  & > span {
    top: 12px;
    position: absolute;
    width: 18px;
    height: 3px;
    display: inline-block;
    transition: all 0.2s ease;

    &:first-of-type {
      left: 0;
      transform: rotate(45deg);
    }
    &:last-of-type {
      right: 0;
      transform: rotate(-45deg);
    }
  }
  &.active span:first-of-type {
    transform: rotate(-45deg);
  }
  &.active span:last-of-type {
    transform: rotate(45deg);
  }
}

/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Globals
 *  2. Headers
 *  3. Navigations
 *  4. Banners
 *  5. Footers
 *  6. Posts
 *  7. Widgets
 *  8. Custom Templates
 */

/*******************
Global Styles
*******************/

// span.badge {
//     font-size: 1rem;
//     padding:10px 20px;
//     margin-right: 1rem;
// }

li > a {
  cursor: pointer;
}

.sidebar-nav ul li a {
  color: white !important;
}

.sidebar-nav #sidebarnav > li > ul {
  background: #2566b4 !important;
}

.sidebar-nav ul li ul {
  padding: 10px 0 !important;
  top: inherit !important;
}

.sidebar-nav #sidebarnav > li:first-of-type {
  margin-left: 50px;
}

.sidebar-nav #sidebarnav > li:last-of-type {
  ul {
    right: 0;
  }
}

.contextSidebar {
  background: #2566b4 !important;
}

ul#sidebarnav {
  background: #2566b4 !important;
  display: flex;
  //justify-content: space-between;

  li {
    margin-right: 1.25rem;

    ul {
      li {
        margin-right: 0;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    a {
      font-size: 16px;
      i {
        width: 15px;
        margin-right: 0.5rem;
        color: white;
      }
    }
  }
}

ul#sidebarnav > li > a {
  color: white;
  text-decoration: none;
}

[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type='checkbox'] {
}

[type='checkbox'] + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

[type='checkbox'] + label:before,
[type='checkbox']:not(.filled-in) + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 1px solid #b1b8bb;
  border-radius: 1px;
  margin-top: 2px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

[type='checkbox']:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

[type='checkbox']:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

[type='checkbox'].tabbed:focus + label:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type='checkbox']:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type='checkbox']:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26);
}

[type='checkbox']:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type='checkbox']:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

[type='checkbox'].filled-in + label:after {
  border-radius: 2px;
}

[type='checkbox'].filled-in + label:after,
[type='checkbox'].filled-in + label:before {
  content: '';
  left: 0;
  position: absolute;
  -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  -o-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}

[type='checkbox'].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  -ms-transform: rotate(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.selectedCalculation {
  font-weight: 600;
  background-color: rgba(255, 0, 0, 0.3);
}

.radio-label::before,
.radio-label::after {
  top: 1.25rem !important;
}

.btn {
  border-radius: 8px;
}

.btn-primary-outline {
  border: 1px solid #1db393;
  width: 144px;
}

.btn-primary {
  border: 1px solid #1db393 !important;
  background: #1db393 !important;
  width: 144px;
}

.wider {
  width: 196px;
}

.btn-grey,
.btn-grey:hover {
  border: 1px solid #b9bbc1 !important;
  background: #b9bbc1 !important;
  color: #ffffff;
}

.btn-primary-blue,
.btn-primary-blue:hover {
  border: 1px solid #2d6cc3 !important;
  background: #2d6cc3 !important;
  width: 200px;
  color: #ffffff;
}

.btn-brown {
  background: #7e6f41 !important;
  border: 1px solid #7e6f41 !important;
  width: 200px;
  color: white;
}

.modal__container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__content {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.pointer {
  cursor: pointer;
}

.custom-control-label::before,
.custom-control-label::after {
  // top: 1.25rem;
  // left: -1.5rem;
  //top: 0.5rem;
  //left: -1.5rem;
  margin-top: -1rem;
  //position: relative;
}

input[type='radio']:checked,
.custom-control-input:checked {
  background-color: 'red';
}

.custom-control-label-checkbox {
  // top: 1.25rem;
  // left: -1.5rem;
}

.companyRow:hover {
  background: rgba(0, 107, 255, 0.16);
}

.companyRowSelected {
  background: rgba(22, 96, 198, 0.64);

  &:hover {
    background: rgba(22, 96, 198, 0.64);
  }
}

.modal__header {
  min-height: 40px;
  background-color: #e3decc;
  padding: 0.5rem 0.75rem;
  color: #8c7d53;
  border-radius: 5px;

  .modal__header_span {
    align-items: center;
    font-size: 22px;
  }
}

.modal__content {
  padding: 1rem;
  background-color: white;
  // height: 70%;
}

.modal__footer {
  color: #8c7d53;
  padding: 0.5rem 0.75rem;
  width: 100%;
  // margin-top: 7px;
  // position: absolute;
  bottom: 0;
  background-color: #e3decc;
  border-radius: 5px;
}

.float-right > i {
  color: #007bff;
}

input[type='file'] {
  width: 1px;
}

.label-right {
  text-align: right;
  font-weight: 600;
  color: #878f9a;
}

.form-container {
  padding: 0 1rem;
}

.creator__btn-icon {
  font-size: 24px;
  color: #1db393 !important;

  &:hover {
    color: #1db393 !important;
  }
}

.details__btn-icon {
  font-size: 24px;
  color: #006BFF;

  &:hover {
    color: #006BFF;
  }
}

.card {
  border-radius: 8px;
}

.card-link {
  font-size: 24px;
}

.card-header {
  line-height: 24px;
  padding: 1.5rem;
}

//
//.custom-control-input:checked ~ .custom-control-label::before {
//  color: #1DB393;
//  background: url('/checkbox-checked.svg');
//}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
  padding: 0.5em;
  padding-right: 1.5em;
}

.badge:hover {
  cursor: pointer;
}

button {
  font-family: system-ui;
  font-weight: 200;
}

.f90 {
  font-size: 90%;
  display: flex;
  align-items: center;
}

.f95 {
  font-size: 95%;
}

button:not(.btn, .btn-green) {
  max-height: 25px;
}

button.btn-40 {
  height: auto;
  max-height: 40px;
}

.btn-sm {
  min-width: 100px;
}

.btn-table {
  width: 75px;
  height: 30px;
}

.DayPickerInput {
  width: 100%;
  min-width: 100%;

  input {
    width: 100%;
    min-width: 100%;
  }
}

.row .data-row {
  &:hover {
    background: rgba(0, 107, 255, 0.12);
    cursor: pointer;
  }

  div {
    font-size: 95%;
  }
}

.page-wrapper {
  background-color: #f6f7f9 !important;
}

.grey {
  color: #878f9a;
}

label {
  font-size: 1rem !important;
  margin-bottom: 0;
}

.t-row {
  display: flex;
  align-items: center;
}

.bl {
  border-left: 0.5px solid #e5e7eb;
  word-wrap: break-word;
}

.br {
  border-right: 0.5px solid #e5e7eb;
  word-wrap: break-word;
}

.bt {
  border-top: 0.5px solid #e5e7eb;
  word-wrap: break-word;
}

.bb {
  border-bottom: 0.5px solid #e5e7eb;
  word-wrap: break-word;
}

.bb-bold {
  border-bottom: 1px solid black;
  word-wrap: break-word;
}

.card-header {
  background-color: white;
  border-radius: 8px;
}

.card-body {
  padding: 0 1.25rem;
}

.rows-bordered {
  .row {
    border-bottom: 1px solid lightgrey;
  }

  .row:first-child {
    border-top: 1px solid lightgrey;
  }

  .row:last-child {
    border: none;
  }
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: transparent;
}

.nav-tabs .nav-item > a {
  color: darkgrey;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-scroller {
  position: fixed;
  width: 100%;
  z-index: 2;
  height: 3rem;
  overflow-y: hidden;
  top: 60px;
  background-color: #2566b4 !important;
  padding: 5px 8px;

  nav {
    button,
    button:hover,
    button:active {
      color: white;
      font-weight: 300;
      font-size: 0.9rem;
    }
  }
}

.navbar-nav {
  a {
    color: white;
  }
}

.navbar,
.topbar {
  background-color: #353a40 !important;
  color: white;
}

.dropdown-menu {
  left: inherit;
  right: 0;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
}

.table-equal [class^='col-'] {
  flex-shrink: 1;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #1976d2;
  border-color: #1976d2 transparent #1976d2 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

s .nav-item,
.nav-link {
  cursor: pointer;
}

* {
  outline: none;
}

button {
  border: 0.5px solid #878f9a;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 10px;
  color: #878f9a;
  font-family: $bodyfont;
  // max-height: 25px;
}

button > span {
  font-family: $bodyfont;
}

body {
  background: #f6f7f9;
  font-family: $bodyfont;
  margin: 0;
  overflow-x: hidden;
  color: $bodytext;
  font-weight: 300;
  font-size: 16px;
}

html {
  position: relative;
  min-height: 100%;
  background: #f6f7f9;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: $headingtext;

  &:hover,
  &:focus {
    color: $themecolor;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-rounded {
  border-radius: $radius;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $headingtext;
  font-family: $headingfont;
  font-weight: 400;
}

h1 {
  line-height: 40px;
  font-size: 32px;
}

h2 {
  line-height: 36px;
  font-size: 24px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
}

h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}

.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 36px;
}

.box {
  border-radius: $radius;
  padding: 10px;
}

html body {
  .dl {
    display: inline-block;
  }

  .db {
    display: block;
  }
}

.no-wrap {
  td,
  th {
    white-space: nowrap;
  }
}

/*******************
Blockquote
*******************/

html body blockquote {
  border-left: 5px solid $themecolor;
  border: 1px solid $border;
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Paddings
*******************/

html body {
  .p-0 {
    padding: 0px;
  }

  .p-10 {
    padding: 10px;
  }

  .p-20 {
    padding: 20px;
  }

  .p-30 {
    padding: 30px;
  }

  .p-l-0 {
    padding-left: 0px;
  }

  .p-l-10 {
    padding-left: 10px;
  }

  .p-l-20 {
    padding-left: 20px;
  }

  .p-r-0 {
    padding-right: 0px;
  }

  .p-r-10 {
    padding-right: 10px;
  }

  .p-r-20 {
    padding-right: 20px;
  }

  .p-r-30 {
    padding-right: 30px;
  }

  .p-r-40 {
    padding-right: 40px;
  }

  .p-t-0 {
    padding-top: 0px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-b-0 {
    padding-bottom: 0px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }

  .p-b-40 {
    padding-bottom: 40px;
  }
}

/*******************
Margin
*******************/

html body {
  .m-0 {
    margin: 0px;
  }

  .m-l-5 {
    margin-left: 5px;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .m-l-15 {
    margin-left: 15px;
  }

  .m-l-20 {
    margin-left: 20px;
  }

  .m-l-30 {
    margin-left: 30px;
  }

  .m-l-40 {
    margin-left: 40px;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-r-15 {
    margin-right: 15px;
  }

  .m-r-20 {
    margin-right: 20px;
  }

  .m-r-30 {
    margin-right: 30px;
  }

  .m-r-40 {
    margin-right: 40px;
  }

  .m-t-0 {
    margin-top: 0px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-t-40 {
    margin-top: 40px;
  }

  .m-b-0 {
    margin-bottom: 0px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-40 {
    margin-bottom: 40px;
  }
}

/*******************
vertical alignment
*******************/

html body {
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }
}

/*******************
Opacity
*******************/

.op-5 {
  opacity: 0.5;
}

/*******************
font weight
*******************/

html body {
  .font-bold {
    font-weight: 700;
  }

  .font-normal {
    font-weight: normal;
  }

  .font-light {
    font-weight: 300;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-10 {
    font-size: 10px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-20 {
    font-size: 20px;
  }
}

/*******************
Border
*******************/

html body {
  .b-0 {
    border: none;
  }

  .b-r {
    border-right: 1px solid $border;
  }

  .b-l {
    border-left: 1px solid $border;
  }

  .b-b {
    border-bottom: 1px solid $border;
  }

  .b-t {
    border-top: 1px solid $border;
  }

  .b-all {
    border: 1px solid $border !important;
  }
}

/*******************
Thumb size
*******************/

.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: $radius;
}

/*******************
Text Colors
*******************/

.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-inverse {
  color: $inverse !important;
}

html body {
  .text-blue {
    color: $blue;
  }

  .text-purple {
    color: $purple;
  }

  .text-primary {
    color: $primary;
  }

  .text-megna {
    color: $megna;
  }

  .text-dark {
    color: $bodytext;
  }

  .text-themecolor {
    color: $themecolor;
  }
}

/*******************
Background Colors
*******************/

.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

html body {
  .bg-megna {
    background-color: $megna;
  }

  .bg-theme {
    background-color: $themecolor;
  }

  .bg-inverse {
    background-color: $inverse;
  }

  .bg-purple {
    background-color: $purple;
  }

  .bg-light-part {
    background-color: $bglight;
  }

  .bg-light-primary {
    background-color: $light-primary;
  }

  .bg-light-success {
    background-color: $light-success;
  }

  .bg-light-info {
    background-color: $light-info;
  }

  .bg-light-extra {
    background-color: $extra-light;
  }

  .bg-light-warning {
    background-color: $light-warning;
  }

  .bg-light-danger {
    background-color: $light-danger;
  }

  .bg-light-inverse {
    background-color: $light-inverse;
  }

  .bg-light {
    background-color: $light;
  }

  .bg-white {
    background-color: $white;
  }
}

/*******************
Rounds
*******************/

.round {
  line-height: 48px;
  color: $white;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: $info;

  img {
    border-radius: 100%;
  }
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.round.round-info {
  background: $info;
}

.round.round-warning {
  background: $warning;
}

.round.round-danger {
  background: $danger;
}

.round.round-success {
  background: $success;
}

.round.round-primary {
  background: $primary;
}

/*******************
Labels
*******************/

.label {
  padding: 3px 10px;
  line-height: 13px;
  color: $white;
  font-weight: 400;
  border-radius: $radius;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: $megna;
}

.label-success {
  background-color: $success;
}

.label-info {
  background-color: $info;
}

.label-warning {
  background-color: $warning;
}

.label-danger {
  background-color: $danger;
}

.label-megna {
  background-color: $megna;
}

.label-primary {
  background-color: $primary;
}

.label-purple {
  background-color: $purple;
}

.label-red {
  background-color: $red;
}

.label-inverse {
  background-color: $inverse;
}

.label-default {
  background-color: $light;
}

.label-white {
  background-color: $white;
}

.label-light-success {
  background-color: $light-success;
  color: $success;
}

.label-light-info {
  background-color: $light-info;
  color: $info;
}

.label-light-warning {
  background-color: $light-warning;
  color: $warning;
}

.label-light-danger {
  background-color: $light-danger;
  color: $danger;
}

.label-light-megna {
  background-color: $light-megna;
  color: $megna;
}

.label-light-primary {
  background-color: $light-primary;
  color: $primary;
}

.label-light-inverse {
  background-color: $light-inverse;
  color: $inverse;
}

/*******************
Pagination
*******************/

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}

.pagination > li > a,
.pagination > li > span {
  color: $dark;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: $light;
}

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: $lft;
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: $themecolor;
  border-color: $themecolor;
}

.pager li > a,
.pager li > span {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
  color: $dark;
}

/*******************
Table Cell
*******************/

.table-box {
  display: table;
  width: 100%;
}

.table.no-border {
  tbody {
    td {
      border: 0px;
    }
  }
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.table td,
.table th {
  border-color: $table-border;
}

.table thead th,
.table th {
  font-weight: 500;
}

.table-hover tbody tr:hover {
  background: $light;
}

.nowrap {
  white-space: nowrap;
}

.lite-padding td {
  padding: 5px;
}

.v-middle {
  td,
  th {
    vertical-align: middle;
  }
}

/*******************
Wave Effects
*******************/

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;

  .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    pointer-events: none;
  }
}

.waves-effect.waves-light {
  .waves-ripple {
    background-color: rgba(255, 255, 255, 0.45);
  }
}

.waves-effect.waves-red {
  .waves-ripple {
    background-color: rgba(244, 67, 54, 0.7);
  }
}

.waves-effect.waves-yellow {
  .waves-ripple {
    background-color: rgba(255, 235, 59, 0.7);
  }
}

.waves-effect.waves-orange {
  .waves-ripple {
    background-color: rgba(255, 152, 0, 0.7);
  }
}

.waves-effect.waves-purple {
  .waves-ripple {
    background-color: rgba(156, 39, 176, 0.7);
  }
}

.waves-effect.waves-green {
  .waves-ripple {
    background-color: rgba(76, 175, 80, 0.7);
  }
}

.waves-effect.waves-teal {
  .waves-ripple {
    background-color: rgba(0, 150, 136, 0.7);
  }
}

html body .waves-notransition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;

  .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.waves-block {
  display: block;
}

/*Badge*/

.badge {
  white-space: normal !important;
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: $success;
}

.badge-info {
  background-color: $info;
}

.badge-primary {
  background-color: $primary;
}

.badge-warning {
  background-color: $warning;
}

.badge-danger {
  background-color: $danger;
}

.badge-purple {
  background-color: $purple;
}

.badge-red {
  background-color: $red;
}

.badge-inverse {
  background-color: $inverse;
}

/*******************
Buttons
******************/

.btn {
  padding: 7px 12px;

  cursor: pointer;
}

.btn-group {
  label {
    color: $white !important;
    margin-bottom: 0px;

    &.btn-secondary {
      color: $bodytext !important;
    }
  }
}

.btn-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.btn-md {
  padding: 12px 55px;
  font-size: 16px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 14px 15px;
  font-size: 18px;
  line-height: 22px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.button-list button,
.button-list a {
  margin: 5px 12px 5px 0;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;

  &.btn-lg {
    padding: 0.75rem 1.5rem;
  }

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
  }

  &.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
  }

  &.btn-md {
    padding: 12px 35px;
    font-size: 16px;
  }
}

.btn-secondary,
.btn-secondary.disabled {
  box-shadow: $default-shadow;
  transition: 0.2s ease-in;
  background-color: $white;
  color: $bodytext;
  border-color: $form-brd;

  &:hover {
    box-shadow: $default-shadow-hover;
  }

  &.active,
  &:active,
  &:focus {
    box-shadow: $default-shadow-hover;
  }
}

.btn-primary,
.btn-primary.disabled {
  background: $primary;
  border: 1px solid $primary;
  box-shadow: $primary-shadow;
  transition: 0.2s ease-in;

  &:hover {
    background: $primary;
    box-shadow: $primary-shadow-hover;
    border: 1px solid $primary;
  }

  &.active,
  &:active,
  &:focus {
    background: $primary-dark;
    box-shadow: $primary-shadow-hover;
  }
}

.btn-themecolor,
.btn-themecolor.disabled {
  background: $themecolor;
  color: $white;
  border: 1px solid $themecolor;

  &:hover {
    background: $themecolor;
    opacity: 0.7;
    border: 1px solid $themecolor;
  }

  &.active,
  &:active,
  &:focus {
    background: $themecolor-dark;
  }
}

.btn-success,
.btn-success.disabled {
  background: $success;
  border: 1px solid $success;
  box-shadow: $success-shadow;
  transition: 0.2s ease-in;

  &:hover {
    background: $success;
    box-shadow: $success-shadow-hover;
    border: 1px solid $success;
  }

  &.active,
  &:active,
  &:focus {
    background: $success-dark;
    box-shadow: $success-shadow-hover;
  }
}

.btn-info,
.btn-info.disabled {
  background: $info;
  border: 1px solid $info;
  box-shadow: $info-shadow;
  transition: 0.2s ease-in;

  &:hover {
    background: $info;
    border: 1px solid $info;
    box-shadow: $info-shadow-hover;
  }

  &.active,
  &:active,
  &:focus {
    background: $info-dark;
    box-shadow: $info-shadow-hover;
  }
}

.btn-warning,
.btn-warning.disabled {
  background: $warning;
  color: $white;
  box-shadow: $warning-shadow;
  border: 1px solid $warning;
  transition: 0.2s ease-in;
  color: $white;

  &:hover {
    background: $warning;
    color: $white;
    box-shadow: $warning-shadow-hover;
    border: 1px solid $warning;
  }

  &.active,
  &:active,
  &:focus {
    background: $warning-dark;
    color: $white;
    box-shadow: $warning-shadow-hover;
  }
}

.btn-danger,
.btn-danger.disabled {
  background: $danger;
  border: 1px solid $danger;
  box-shadow: $danger-shadow;
  transition: 0.2s ease-in;

  &:hover {
    background: $danger;
    box-shadow: $danger-shadow-hover;
    border: 1px solid $danger;
  }

  &.active,
  &:active,
  &:focus {
    background: $danger-dark;
    box-shadow: $danger-shadow-hover;
  }
}

.btn-inverse,
.btn-inverse.disabled {
  background: $inverse;
  border: 1px solid $inverse;
  color: $white;

  &:hover {
    background: $inverse;
    opacity: 0.7;
    color: $white;
    border: 1px solid $inverse;
  }

  &.active,
  &:active,
  &:focus {
    background: $inverse-dark;
    color: $white;
  }
}

.btn-red,
.btn-red.disabled {
  background: $red;
  border: 1px solid $red;
  color: $white;

  &:hover {
    opacity: 0.7;
    border: 1px solid $red;
    background: $red;
  }

  &.active,
  &:active,
  &:focus {
    background: $danger-dark;
  }
}

.btn-outline-secondary {
  background-color: $white;
  box-shadow: $default-shadow;
  transition: 0.2s ease-in;

  &:hover,
  &:focus,
  &.focus {
    box-shadow: $default-shadow-hover;
  }

  &.active,
  &:active,
  &:focus {
    box-shadow: $default-shadow-hover;
  }
}

.btn-outline-primary {
  color: $primary;
  background-color: $white;
  border-color: $primary;
  box-shadow: $primary-shadow;
  transition: 0.2s ease-in;

  &:hover,
  &:focus,
  &.focus {
    background: $primary;
    box-shadow: $primary-shadow-hover;
    color: $white;
    border-color: $primary;
  }

  &.active,
  &:active,
  &:focus {
    box-shadow: $primary-shadow-hover;
    background: $primary-dark;
  }
}

.btn-outline-success {
  color: $success;
  background-color: transparent;
  border-color: $success;
  box-shadow: $success-shadow;
  transition: 0.2s ease-in;

  &:hover,
  &:focus,
  &.focus {
    background: $success;
    border-color: $success;
    color: $white;
    box-shadow: $success-shadow-hover;
  }

  &.active,
  &:active,
  &:focus {
    box-shadow: $success-shadow-hover;
    background: $success-dark;
  }
}

.btn-outline-info {
  color: $info;
  background-color: transparent;
  border-color: $info;
  box-shadow: $info-shadow;
  transition: 0.2s ease-in;

  &:hover,
  &:focus,
  &.focus {
    background: $info;
    border-color: $info;
    color: $white;
    box-shadow: $info-shadow-hover;
  }

  &.active,
  &:active,
  &:focus {
    box-shadow: $info-shadow-hover;
    background: $info-dark;
  }
}

.btn-outline-warning {
  color: $warning;
  background-color: transparent;
  border-color: $warning;
  box-shadow: $warning-shadow;
  transition: 0.2s ease-in;

  &:hover,
  &:focus,
  &.focus {
    background: $warning;
    border-color: $warning;
    color: $white;
    box-shadow: $warning-shadow-hover;
  }

  &.active,
  &:active,
  &:focus {
    box-shadow: $warning-shadow-hover;
    background: $warning-dark;
  }
}

.btn-outline-danger {
  color: $danger;
  background-color: transparent;
  border-color: $danger;
  box-shadow: $danger-shadow;
  transition: 0.2s ease-in;

  &:hover,
  &:focus,
  &.focus {
    background: $danger;
    border-color: $danger;
    color: $white;
    box-shadow: $danger-shadow-hover;
  }

  &.active,
  &:active,
  &:focus {
    box-shadow: $danger-shadow-hover;
    background: $danger-dark;
  }
}

.btn-outline-red {
  color: $red;
  background-color: transparent;
  border-color: $red;

  &:hover,
  &:focus,
  &.focus {
    background: $red;
    border-color: $red;
    color: $white;
    box-shadow: $danger-shadow-hover;
  }

  &.active,
  &:active,
  &:focus {
    box-shadow: $danger-shadow-hover;
    background: $danger-dark;
  }
}

.btn-outline-inverse {
  color: $inverse;
  background-color: transparent;
  border-color: $inverse;

  &:hover,
  &:focus,
  &.focus {
    background: $inverse;
    border-color: $inverse;
    color: $white;
  }
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: $primary-dark;
  border: 1px solid $primary-dark;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: $success-dark;
  border: 1px solid $success-dark;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color: $info-dark;
  border: 1px solid $info-dark;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: $warning-dark;
  border: 1px solid $warning-dark;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: $danger-dark;
  border: 1px solid $danger-dark;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: $inverse-dark;
  border: 1px solid $inverse-dark;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
  background-color: $red-dark;
  border: 1px solid $red-dark;
  color: $white;
}

.button-box .btn {
  margin: 0 8px 8px 0px;
}

.btn-label {
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin: -6px 12px -6px -14px;
  padding: 7px 15px;
}

.btn-facebook {
  color: $white;
  background-color: #3b5998;
}

.btn-twitter {
  color: $white;
  background-color: #55acee;
}

.btn-linkedin {
  color: $white;
  background-color: #007bb6;
}

.btn-dribbble {
  color: $white;
  background-color: #ea4c89;
}

.btn-googleplus {
  color: $white;
  background-color: #dd4b39;
}

.btn-instagram {
  color: $white;
  background-color: #3f729b;
}

.btn-pinterest {
  color: $white;
  background-color: #cb2027;
}

.btn-dropbox {
  color: $white;
  background-color: #007ee5;
}

.btn-flickr {
  color: $white;
  background-color: #ff0084;
}

.btn-tumblr {
  color: $white;
  background-color: #32506d;
}

.btn-skype {
  color: $white;
  background-color: #00aff0;
}

.btn-youtube {
  color: $white;
  background-color: #bb0000;
}

.btn-github {
  color: $white;
  background-color: #171515;
}

/*******************
Notify
*******************/

.notify {
  position: relative;
  top: -22px;
  right: -9px;

  .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid $danger;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: $danger;
    position: absolute;
    right: 6px;
    top: -10px;
  }
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

/*******************
File Upload
******************/

.fileupload {
  overflow: hidden;
  position: relative;

  input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

/*******************
Megamenu
******************/

.mega-dropdown {
  position: static;
  width: 100%;

  .dropdown-menu {
    width: 100%;
    padding: 30px;
    margin-top: 0px;
  }

  ul {
    padding: 0px;

    li {
      list-style: none;
    }
  }

  .carousel-item .container {
    padding: 0px;
  }

  .nav-accordion {
    .card {
      margin-bottom: 1px;
    }

    .card-header {
      background: $white;

      h5 {
        margin: 0px;

        a {
          text-decoration: none;
          color: $bodytext;
        }
      }
    }
  }
}

/*******************
List-style-none
******************/

ul.list-style-none {
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;

    a {
      color: $bodytext;
      padding: 8px 0px;
      display: block;
      text-decoration: none;

      &:hover {
        color: $themecolor;
      }
    }
  }
}

/*******************
dropdown-item
******************/

.dropdown-item {
  padding: 8px 1rem;
  color: $bodytext;
}

/*******************
Custom-select
******************/

.custom-select {
  // background: url(../../assets/images/custom-select.png) right .75rem center no-repeat;
}

/*******************
textarea
******************/

textarea {
  resize: none;
}

/*******************
Form-control
******************/

.form-control {
  color: $bodytext;
  height: 35px;
  font-size: 90%;
  display: initial;
}

.form-control-danger {
  border-color: red;
}

.form-control-sm {
  min-height: 20px;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.7;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: $success;
}

form label {
  font-weight: 400;
}

small,
.small {
  font-size: 75%;
  font-weight: 200;
}

.form-horizontal label {
  margin-bottom: 0px;
}

.form-control-static {
  padding-top: 0px;
}

.form-bordered .form-group {
  border-bottom: 1px solid $border;
  padding-bottom: 20px;
}

.form-group {
  margin-bottom: 0.35rem;
}

/*******************
Layouts
******************/

/*Card-noborders*/

.card-no-border {
  .card {
    border: 0px;
    border-radius: $radius;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  }
}

.card-no-border .shadow-none {
  box-shadow: none;
}

.card-outline-danger,
.card-outline-info,
.card-outline-warning,
.card-outline-success,
.card-outline-primary {
  background: $white;
}

.card-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
}

/*******************/

/*widgets -app*/

/*******************/

.css-bar:after {
  z-index: 1;
}

.css-bar > i {
  z-index: 10;
}

/*******************/

/*single column*/

/*******************/

.single-column {
  .left-sidebar {
    display: none;
  }

  .page-wrapper {
    margin-left: 0px;
  }
}

.fix-width {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

/*******************/

/*card-default*/

.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1db393;
  border-radius: 8px;
  margin-bottom: 15px !important;
}

/*******************/

.card-default {
  .card-header {
    background: $white;
    border-bottom: 0px;
  }
}

/***********************
 13/02/2018 (New Update)
***********************/
.progress {
  height: auto;
}

.card-group {
  margin-bottom: 30px;
}

.page-item {
  cursor: pointer;
}

.tab-button {
  width: 212px;
  height: 80px;
  background: #b9bbc1 0% 0% no-repeat padding-box;

  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  &:hover {
    color: #ffffff;
  }
}

button:not([disabled]).btn-green {
  background: #f1ffea 0% 0% no-repeat padding-box;
}

.tab-button-creator-active {
  background: #1db393 0% 0% no-repeat padding-box;
}

.tab-button-active {
  background: #2566b4 0% 0% no-repeat padding-box;
}

table.selectable {
  border: 0.5px solid #e5e7eb;
  border-left: 0;
  border-right: 0;
  border-collapse: collapse;
  border-spacing: 15px;
  width: 100%;
  table-layout: auto;

  tbody > tr:hover {
    background: rgba(0, 107, 255, 0.12);
    cursor: pointer;
  }
}

th {
  color: #878f9a;
  font-weight: lighter;
}

th:not(.no-borders),
td:not(.no-borders) {
  text-align: left;
  border: 0.5px solid #e5e7eb;
  padding: 0.5rem 0.5rem;
  max-width: 700px;
}

.badge-warning {
  background-color: #f7daa7 !important;
}

.creator {
  color: #1db393 !important;
}

.details {
  color: rgb(0, 107, 255) !important;
}

button:disabled {
  cursor: not-allowed;
}

.form-group {
  width: 100%
}

a.collapsed {
  color: #006BFF;
}
